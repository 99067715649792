<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-primary my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>

        <b-form v-else
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="title"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`title.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"

                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model="form.title[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="country_id"
                :name="$t('country')"

              >
                <b-form-group
                  :label="$t('country')"
                >
                  <v-select
                    v-model="form.country_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="countries"
                    :reduce="item => item.id"
                    :placeholder="$t('country')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="region_id"
                :name="$t('region')"

              >
                <b-form-group
                  :label="$t('region')"
                >
                  <v-select
                    v-model="form.region_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="regions"
                    :reduce="item => item.id"
                    :placeholder="$t('region')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="city_id"
                :name="$t('city')"

              >
                <b-form-group
                  :label="$t('city')"
                >
                  <v-select
                    v-model="form.city_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="cities"
                    :reduce="item => item.id"
                    :placeholder="$t('city')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.number_of_seats')"
                vid="number_of_seats"
              >
                <b-form-group
                  :label="$t('labels.number_of_seats')"
                >
                  <b-form-input
                    v-model="form.number_of_seats"
                    :placeholder="$t('labels.number_of_seats')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.width')"
                vid="width"
              >
                <b-form-group
                  :label="$t('labels.width')"
                >
                  <b-form-input
                    v-model="form.width"
                    :placeholder="$t('labels.width')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.length')"
                vid="length"
              >
                <b-form-group
                  :label="$t('labels.length')"
                >
                  <b-form-input
                    v-model="form.length"
                    :placeholder="$t('labels.length')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.luminous_power')"
                vid="luminous_power"

              >
                <b-form-group
                  :label="$t('labels.luminous_power')"
                >
                  <b-form-input
                    v-model="form.luminous_power"
                    :placeholder="$t('labels.luminous_power')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.creation_year')"
                vid="year_creation"
              >
                <b-form-group>
                  <DatePicker
                    v-model="form.year_creation"
                    :label="$t('labels.creation_year')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="description"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`description.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"

                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-textarea
                        v-model="form.description[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rows="4"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                vid="image"
                :name="$t('labels.image')"

              >
                <ImagePreview
                  v-model="image"
                  :label="$t('labels.image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <b-input-group class="mb-2">
                <gmap-autocomplete
                  :placeholder="$t('enter_location')"
                  class="form-control"
                  @place_changed="setPlace"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="addMarker"
                  >
                    {{ $t('Add') }}
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <gmap-map
                :center="center"
                :zoom="zoom"
                style="width:100%;  height: 400px;"
              >
                <GmapMarker
                  v-for="(m, index) in markers"
                  :key="index"
                  :position="m.position"
                  @click="center=m.position"
                />
              </gmap-map>
            </b-col>

            <b-col cols="12">
              <br>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.latitude')"
                vid="latitude"
              >
                <b-form-group
                  :label="$t('labels.latitude')"
                >
                  <b-form-input
                    v-model="form.latitude"
                    :placeholder="$t('labels.latitude')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.longitude')"
                vid="longitude"
              >
                <b-form-group
                  :label="$t('labels.longitude')"
                >
                  <b-form-input
                    v-model="form.longitude"
                    :placeholder="$t('labels.longitude')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- checkbox -->
            <b-col
              md="6"
              cols="12"
            >
              <b-card-text class="mb-0">
                {{ $t('labels.is_closed') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.is_closed"
                checked="true"
                value="1"
                unchecked-value="0"
                name="check-button"
                switch
              />
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <b-card-text class="mb-0">
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded:false,
      image: null,
      countries: [],
      regions: [],
      cities: [],
      center: {
        lat: 45.508,
        lng: -73.587,
      },
      markers: [],
      places: [],
      zoom: 12,
      currentPlace: null,
      form: {
        title: {
          en: null,
          ar: null,
        },
        country_id: null,
        city_id: null,
        region_id: null,
        year_creation: null,
        is_closed: 0,
        number_of_seats: null,
        width: null,
        length: null,
        luminous_power: null,
        latitude: null,
        longitude: null,
        description: {
          en: null,
          ar: null,
        },
        image: null,
        status: 'active',
      },
    }
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
    'form.country_id': {
      handler($val) {
        this.getRegions($val)
      },
      deep: true,
    },
    'form.region_id': {
      handler($val) {
        this.getCities($val)
      },
      deep: true,
    },
  },
  created() {
    this.getCountries()
    this.geolocate()
    if (this.isEdit) {
      this.loadData()
    }else {
      this.isDataLoaded = true
    }
  },
  methods: {
    getCountries() {
      this.axios.get('/lists/Country')
        .then(res => {
          this.countries = res.data
        })
    },
    getRegions($id) {
      this.axios.get(`/lists/regions/${$id}`)
        .then(res => {
          this.regions = res.data
        })
    },
    getCities($id) {
      this.axios.get(`/lists/cities/${$id}`)
        .then(res => {
          this.cities = res.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.title,
            description: data.description,
            country_id: data.country_id,
            city_id: data.city_id,
            region_id: data.region_id,
            year_creation: data.year_creation,
            is_closed: data.is_closed,
            number_of_seats: data.number_of_seats,
            width: data.width,
            length: data.length,
            luminous_power: data.luminous_power,
            status: data.status,
            latitude: data.latitude,
            longitude: data.longitude,
          }
          const marker = {
            lat: parseFloat(data.latitude),
            lng: parseFloat(data.longitude),
          }
          this.markers.push({ position: marker })
          this.center = marker
          this.zoom = 18
          this.image = data.image
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (!($form.image instanceof File)) {
        delete $form.image
      }
      return $form
    },
    setPlace(place) {
      this.currentPlace = place
    },
    addMarker() {
      this.markers = []
      this.places = []
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        }
        this.form.latitude = this.currentPlace.geometry.location.lat()
        this.form.longitude = this.currentPlace.geometry.location.lng()
        this.markers.push({ position: marker })
        this.places.push(this.currentPlace)
        this.zoom = 18
        this.center = marker
        this.currentPlace = null
      }
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      })
    },
  },

}
</script>
